// Mixins
import BaseMixin from '@/mixins/base'
import ImagesMixin from '@/mixins/images'

// Data
import pageData from '@/data/page'

// Helpers
import { decode } from '@/helpers/string'

export default {
  pageName: 'home',

  mixins: [BaseMixin, ImagesMixin],

  computed: {
    page () {
      return pageData(this.$options.pageName)
    },

    meta () {
      return this.page.meta
    },

    content () {
      return this.page.content
    },

    title () {
      return this.meta.title
    },

    description () {
      return this.meta.description
    }
  },

  head: {
    title: function () {
      return {
        inner: decode(this.title)
      }
    },

    meta: function () {
      const meta = [
        { p: 'description', c: decode(this.description), id: 'description' },
        { n: 'author', c: decode(process.env.VUE_APP_AUTHOR_NAME), id: 'author' },
        { n: 'application-name', c: this.title, id: 'application-name' },
        { n: 'apple-mobile-web-app-title', c: this.title, id: 'apple-mobile-web-app-title' },
        // OpenGraph
        { p: 'og:title', c: this.title, id: 'og:title' },
        { p: 'og:site_name', c: this.title, id: 'og:site_name' },
        { p: 'og:description', c: decode(this.description), id: 'og:description' },
        { p: 'og:image', c: `${process.env.VUE_APP_URL}${this.allImages['seo/une-histoire-vraie_1200x630'].bind.src}`, id: 'og:image' },
        { p: 'og:image:alt', c: this.title, id: 'og:image:alt' },
        { p: 'og:url', c: window.location.href, id: 'og:url' },
        // Twitter
        { p: 'twitter:title', c: this.title, id: 'twitter:title' },
        { p: 'twitter:description', c: decode(this.description), id: 'twitter:description' },
        { p: 'twitter:image', c: `${process.env.VUE_APP_URL}${this.allImages['seo/une-histoire-vraie_900x450'].bind.src}`, id: 'twitter:image' },
        { p: 'twitter:site', c: `@${process.env.VUE_APP_TWITTER_USERNAME}`, id: 'twitter:site' },
        { p: 'twitter:creator', c: `@${process.env.VUE_APP_AUTHOR_TWITTER}`, id: 'twitter:creator' },
        // Favicon
        { p: 'msapplication-TileColor', c: '#ffffff', id: 'msapplication-TileColor' },
        { p: 'msapplication-TileImage', c: this.allImages['seo/ms-icon-144x144'].bind.src, id: 'msapplication-TileImage' },
        { p: 'theme-color', c: '#ffffff', id: 'theme-color' }
      ]

      return meta
    },

    link: function () {
      return [
        // Favicon
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-57x57'].bind.src, sizes: '57x57', id: 'apple-touch-icon' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-60x60'].bind.src, sizes: '60x60', id: 'apple-touch-icon' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-72x72'].bind.src, sizes: '72x72', id: 'apple-touch-icon' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-76x76'].bind.src, sizes: '76x76', id: 'apple-touch-icon' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-114x114'].bind.src, sizes: '114x114', id: 'apple-touch-icon' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-120x120'].bind.src, sizes: '120x120', id: 'apple-touch-icon' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-144x144'].bind.src, sizes: '144x114', id: 'apple-touch-icon' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-152x152'].bind.src, sizes: '152x152', id: 'apple-touch-icon' },
        { rel: 'apple-touch-icon', href: this.allImages['seo/apple-icon-180x180'].bind.src, sizes: '180x180', id: 'apple-touch-icon' },
        { rel: 'icon', href: this.allImages['seo/android-icon-192x192'].bind.src, sizes: '192x192', type: 'image/png', id: 'icon' },
        { rel: 'icon', href: this.allImages['seo/favicon-32x32'].bind.src, sizes: '32x32', type: 'image/png', id: 'icon' },
        { rel: 'icon', href: this.allImages['seo/favicon-96x96'].bind.src, sizes: '96x96', type: 'image/png', id: 'icon' },
        { rel: 'icon', href: this.allImages['seo/favicon-16x16'].bind.src, sizes: '16x16', type: 'image/png', id: 'icon' }
      ]
    }
  },

  beforeMount () {
    document.body.setAttribute('data-page', this.$route.name)
  }
}
