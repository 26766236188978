import Vue from 'vue'
import Router from 'vue-router'
import VueHead from 'vue-head'
import multiguard from 'vue-router-multiguard'

// Pages
import PHome from '@/views/PHome'
import PStory from '@/views/PStory'
import PStoryDetails from '@/views/PStoryDetails'

// Middlewares
import { getStaticContent } from '@/middlewares/content'
import { getDeviceInformations } from '@/middlewares/device'
import { loadMainVideo } from '@/middlewares/media'
import { hideLoader } from '@/middlewares/loader'

Vue.use(Router)
Vue.use(VueHead, {
  separator: '',
  complement: ''
})

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: PHome,
      beforeEnter: multiguard([getStaticContent, getDeviceInformations, hideLoader])
    },
    {
      path: '/:slug',
      name: 'story',
      component: PStory,
      beforeEnter: multiguard([getStaticContent, getDeviceInformations, hideLoader])
    },
    {
      path: '/:slug/details',
      name: 'story-details',
      component: PStoryDetails,
      beforeEnter: multiguard([getStaticContent, getDeviceInformations, hideLoader])
    }
  ],
  /**
   * Router Scroll behavior
   * @see: https://router.vuejs.org/guide/advanced/scroll-behavior.html#scroll-behavior
   */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
