<template>
  <p class="uhv-text"
     ref="text"
     v-html="text"></p>
</template>

<script>
  export default {
    name: 'UHVText',

    props: {
      text: {
        type: String,
        default: ''
      }
    },

    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .uhv-text {

  }
</style>
