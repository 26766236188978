<template lang="html">
  <button
    class="btn-info"
    type="button"
    :disabled="disabled"
    @click="$emit('click')"
  >
    infos
  </button>
</template>

<script>
  export default {
    name: 'UHVInfoButton',
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
