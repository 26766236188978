export default () => {
  return {
    meta: {
      title: 'L’histoire vraie du recyclage — ecosystem',
      description: 'Voici notre histoire. Une histoire vraie, essentielle, intense.'
    },

    content: {}
  }
}
