<template>
  <div
    class="app"
    :class="elClasses"
  >
    <uhv-loader />
    <uhv-orientation />

    <transition
      :name="transitionName"
      mode="in-out"
    >
      <router-view ref="page" />
    </transition>
  </div>
</template>

<script>
  // Store
  import { GETTERS as G, MUTATIONS as M, STATE as S } from '@/store/helpers'

  // Behavior
  import ScrollRAF from '@/behavior/scrollRAF'

  // Helpers
  import { isAutoplaySupportedOldBrowser } from '@/helpers/videos'
  import { throttle } from '@/helpers/globals'
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import UHVLoader from '@/components/loader/UHVLoader'
  import UHVOrientation from '@/components/orientation/UHVOrientation'

  export default {
    components: {
      'uhv-loader': UHVLoader,
      'uhv-orientation': UHVOrientation
    },

    data () {
      return {
        env: process.env.VUE_APP_ENV,
        error: null,
        transitionName: 'fade'
      }
    },

    computed: {
      elClasses () {
        return [
          this.loading ? 'is-loading' : ''
        ]
      },

      windowW () {
        return this.$store.state[S.windowW]
      },

      windowH () {
        return this.$store.state[S.windowH]
      },

      isMobileDevice () {
        return this.$store.getters[G.isMobileDevice]
      },

      support () {
        return this.$store.getters[G.support]
      },

      contentIsFetched () {
        return this.$store.state[S.contentIsFetched]
      }
    },

    watch: {
      '$route': '_onRouteChange',
      contentIsFetched: '_onContentIsFetchedChange'
    },

    beforeCreate () {
      isAutoplaySupportedOldBrowser().then(() => {
        this.$store.commit(M.autoplayVideo, true)
      }).catch(() => {
        this.$store.commit(M.autoplayVideo, false)
      })
    },

    created () {
      this._updateBodyClass()
    },

    mounted () {
      this.$store.commit(M.mediaQuery)
      window.addEventListener('resize', this.onResize)

      this.onResize()

      if (this.contentIsFetched) {
        this._onContentIsFetchedChange()
      }
    },

    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
      document.removeEventListener('app:back-to-top', this.handleBackToTop, false)
    },

    methods: {
      // Watchers
      _onRouteChange (to, from) {
        if (to.name === 'story-details' && from.name === 'story') {
          this.transitionName = 'slide-to-top'
        } else if (to.name === 'story' && from.name === 'story-details') {
          this.transitionName = 'slide-to-bottom'
        } else if (to.name === 'story' && from.name === 'home') {
          this.transitionName = 'slide-to-left'
        } else if (to.name === 'home' && from.name === 'story') {
          this.transitionName = 'slide-to-right'
        } else if (to.name === 'story-details' && from.name === 'home') {
          this.transitionName = 'slide-to-top'
        }

        if (from.name !== null) {
          this.$store.commit(M.updateHasLoader, false)
        }
      },

      _onContentIsFetchedChange () {
        if (this.contentIsFetched) {
          window.contentIsFetched = true
          document.dispatchEvent(new CustomEvent('app:pre-rendering'))
        }
      },

      // BODY
      _updateBodyClass () {
        const $body = document.body

        if ($body && this.support.classes.length > 0) {
          $body.className = ''

          this.support.classes.map(c => $body.classList.add(c))

          $body.classList.add(`is-${this.$store.getters[G.deviceOrientation]}`)
          $body.classList.add(`color-scheme--${this.$store.getters[G.colorScheme]}`)
        }
      },

      // Child's Events
      loaderHidden () {
        this.loading = false
      },

      // RAF
      scrollRafCallback (event) {
        this.toggleVisibilityTracker(event.scrollY)
      },

      toggleVisibilityTracker (scroll) {
        this.menuIsShown = scroll >= this.showMenuOffset
      },

      startRaf () {
        if (this.scrollRafID) return
        this.$nextTick(() => {
          this.scrollRafID = ScrollRAF.add(this.scrollRafCallback)
        })
      },

      stopRaf () {
        if (this.scrollRafID) this.scrollRafID = ScrollRAF.remove(this.scrollRafID)
      },

      // Events
      onResize: throttle(function () {
        this.$store.commit(M.mediaQuery)
        this.$store.commit(M.windowW)
        this.$store.commit(M.windowH)

        this._updateBodyClass()

        EventBus.$emit('resize')
      }, 200)
    }
  }
</script>

<style lang="scss">
  @import "scss/app";

  html,
  body {
    // overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  #main + footer {
    background-color: #FFF;
  }
</style>

<style lang="scss" scoped>
  .app {
    position: relative;
    z-index: 1;

    min-height: 100vh;
    // height: 100vh;
    // height: calc(var(--vh, 1vh) * 100);
    // overflow: hidden;
  }

  $slide-transition-duration: 0.5s;

  // Slide to top
  .slide-to-top-enter-active,
  .slide-to-top-leave-active {
    max-height: calc(var(--vh, 1vh) * 100);
  }

  .slide-to-top-enter-active {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    transform: translateY(100%) translateZ(0);

    width: inherit;

    opacity: 1;

    transition: transform $slide-transition-duration $ease-out-quint;
    will-change: transform;
  }

  .slide-to-top-enter-to {
    transform: translateY(0) translateZ(0);
  }

  .slide-to-top-leave-active {
    display: none;

    opacity: 0;
  }

  // Slide to right
  .slide-to-right-enter-active,
  .slide-to-right-leave-active {
    max-height: calc(var(--vh, 1vh) * 100);
  }

  .slide-to-right-leave-active {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateX(0) translateZ(0);

    width: inherit;

    transition: transform $slide-transition-duration $ease-out-quint;
    will-change: transform;
  }

  .slide-to-right-leave-to {
    transform: translateX(100%) translateZ(0);
  }

  // Slide to bottom
  .slide-to-bottom-enter-active,
  .slide-to-bottom-leave-active {
    max-height: calc(var(--vh, 1vh) * 100);
  }

  .slide-to-bottom-leave-active {
    position: absolute !important;

    transition: transform $slide-transition-duration $ease-out-quint;
    will-change: transform;
  }

  .slide-to-bottom-leave-to {
    transform: translateY(100%) translateZ(0);
  }

  // Slide to left
  .slide-to-left-enter-active,
  .slide-to-left-leave-active {
    position: relative;
    z-index: 1;

    max-height: calc(var(--vh, 1vh) * 100);
  }

  .slide-to-left-enter-active {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    transform: translateX(100%) translateZ(0);
    z-index: 2;

    width: inherit;

    opacity: 1;

    transition: transform $slide-transition-duration $ease-out-quint;
    will-change: transform;
  }

  .slide-to-left-enter-to {
    transform: translateX(0) translateZ(0);
  }

  .slide-to-left-leave-active {
    display: none;

    opacity: 0;
  }
</style>
