import Vue from 'vue'
import Vuex from 'vuex'

// Helpers
import { isTouchDevice, tabletBreakpoints, mobileBreakpoints, getCurrentMediaQuery } from '@/helpers/globals'
import { getColorScheme } from '@/helpers/dark-mode'
import { getLang } from '@/helpers/lang'
import Support from '@/helpers/support'
import { STATE, MUTATIONS, GETTERS, ACTIONS } from '@/store/helpers'
import { fetchStaticContent } from '@/controllers/ContentController'

// Data
import imagesData from '@/data/images'
import globalData from '@/data/global'

Vue.use(Vuex)

export const state = {
  // Device informations
  [STATE.support]: new Support(),
  [STATE.isTouchDevice]: isTouchDevice(),
  [STATE.isRetina]: window.devicePixelRatio > 1,
  [STATE.windowW]: window.inenrWidth,
  [STATE.windowH]: window.innerHeight,
  [STATE.pageHeight]: 0,
  [STATE.canAutoPlayVideo]: false,
  [STATE.videoFormatNameToLoad]: 'desktop',
  // User preferences
  [STATE.lang]: getLang(),
  [STATE.direction]: 'ltr',
  [STATE.mediaQuery]: getCurrentMediaQuery(),
  [STATE.enableLazyLoad]: true,
  [STATE.colorScheme]: getColorScheme(),
  // Events
  [STATE.gtmEventsSent]: [],
  [STATE.hasLoader]: true,
  [STATE.loaderPercent]: 0,
  [STATE.loaderIsVisible]: true,
  [STATE.mainVideoIsLoaded]: false,
  [STATE.mainVideoCurrentTime]: 0,
  // Data
  [STATE.images]: imagesData(),
  [STATE.globalData]: globalData(),
  [STATE.staticContent]: null,
  [STATE.contentIsFetched]: false,
  [STATE.numberOfContentFetched]: 0,
  [STATE.menuIsOpen]: true,
  [STATE.lastStorySlugOpened]: null
}

export const getters = {
  // Device informations
  [GETTERS.support]: state => state[STATE.support],
  [GETTERS.isTabletDevice]: state => tabletBreakpoints.includes(state[STATE.mediaQuery]),
  [GETTERS.isMobileDevice]: state => mobileBreakpoints.includes(state[STATE.mediaQuery]),
  [GETTERS.deviceOrientation]: state => {
    if (state[STATE.windowW] > state[STATE.windowH]) {
      return 'landscape'
    } else if (state[STATE.windowW] < state[STATE.windowH]) {
      return 'portrait'
    }

    return 'square'
  },
  // User preferences
  [GETTERS.lang]: state => state[STATE.lang],
  [GETTERS.colorScheme]: state => state[STATE.colorScheme],
  // Events
  [GETTERS.isGtmEventSent]: state => eventUid => state[STATE.gtmEventsSent].includes(eventUid),
  [GETTERS.loaderPercent]: state => state[STATE.loaderPercent],
  [GETTERS.loaderIsVisible]: state => state[STATE.loaderIsVisible],
  // Data
  [GETTERS.images]: state => state[STATE.images],
  [GETTERS.globalData]: state => state[STATE.globalData],
  [STATE.lastStorySlugOpened]: state => state[STATE.lastStorySlugOpened]
}

export const mutations = {
  // Device informations
  [MUTATIONS.support] (state, support) {
    state[STATE.support] = support
  },
  [MUTATIONS.windowW] (state) {
    state[STATE.windowW] = window.innerWidth
  },
  [MUTATIONS.windowH] (state) {
    state[STATE.windowH] = window.innerHeight
  },
  [MUTATIONS.pageHeight] (state, pageHeight) {
    state[STATE.pageHeight] = pageHeight
  },
  [MUTATIONS.autoplayVideo] (state, autoplay) {
    state[STATE.canAutoPlayVideo] = autoplay
  },
  [MUTATIONS.videoFormatNameToLoad] (state, videoFormatName = 'desktop') {
    state[STATE.videoFormatNameToLoad] = videoFormatName
  },
  // User preferences
  [MUTATIONS.lang] (state, lang) {
    state[STATE.lang] = lang
  },
  [MUTATIONS.direction] (state, direction) {
    state[STATE.direction] = direction
  },
  [MUTATIONS.mediaQuery] (state) {
    state[STATE.mediaQuery] = getCurrentMediaQuery()
  },
  [MUTATIONS.enableLazyLoad] (state) {
    state[STATE.enableLazyLoad] = true
  },
  [MUTATIONS.colorScheme] (state, colorScheme) {
    state[STATE.colorScheme] = colorScheme
  },
  // Events
  [MUTATIONS.sendGtmEvent] (state, eventUid) {
    state[STATE.gtmEventsSent].push(eventUid)
  },
  [MUTATIONS.updateHasLoader] (state, hasLoader = false) {
    state[STATE.hasLoader] = hasLoader
  },
  [MUTATIONS.updateLoaderVisibility] (state, visibility = false) {
    state[STATE.loaderIsVisible] = visibility
  },
  [MUTATIONS.updateLoaderPercent] (state, percent = 0) {
    state[STATE.loaderPercent] = percent
  },
  [MUTATIONS.updateMainVideoIsLoaded] (state, loaded = true) {
    state[STATE.mainVideoIsLoaded] = loaded
  },
  [MUTATIONS.updateMainVideoCurrentTime] (state, currentTime = 0) {
    state[STATE.mainVideoCurrentTime] = currentTime
  },
  // Data
  [MUTATIONS.images] (state, images) {
    state[STATE.images] = images
  },
  [MUTATIONS.globalData] (state, globalData) {
    state[STATE.globalData] = globalData
  },
  [MUTATIONS.contentIsFetched] (state, contentIsFetched = true) {
    state[STATE.contentIsFetched] = contentIsFetched
  },
  [MUTATIONS.staticContent] (state, staticContent) {
    state[STATE.staticContent] = staticContent
  },
  [MUTATIONS.numberOfContentFetched] (state, numberOfContentFetched) {
    state[STATE.numberOfContentFetched] = numberOfContentFetched
  },
  [MUTATIONS.updateMenuIsOpen] (state, open = false) {
    state[STATE.menuIsOpen] = open
  },
  [MUTATIONS.updateLastStorySlugOpened] (state, slug = null) {
    state[STATE.lastStorySlugOpened] = slug
  }
}

export const actions = {
  async [ACTIONS.fetchStaticContent] ({ commit, state }) {
    const content = await fetchStaticContent()

    commit(MUTATIONS.staticContent, content)
    commit(MUTATIONS.numberOfContentFetched, state[STATE.numberOfContentFetched] + 1)
  }
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
