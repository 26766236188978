import 'es6-promise/auto'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import VueGtag from 'vue-gtag'

import App from './App.vue'
import router from './router/router'
import store from './store'

// Components
import UHVLazyLoad from '@/components/media/UHVImageLazyLoad'
import UHVMedia from '@/components/media/UHVMedia'
import UHVImage from '@/components/media/UHVImage'
import UHVIcon from '@/components/svg/UHVSvgIcon'
import UHVText from '@/components/text/UHVText'
import UHVButton from '@/components/button/UHVButton'

import { ObserveVisibility } from 'vue-observe-visibility'

require('intersection-observer')
require('@/polyfills/customEvent')

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production'

Vue.use(VueGtag, {
  config: {
    id: 'G-8GE07CMTML',
    params: {
      send_page_view: true
    }
  },
  includes: [{
    id: 'GTM-K8S2XJ4',
    params: {
      send_page_view: true
    }
  }]
}, router)

Vue.use(VueAnalytics, {
  id: ['G-8GE07CMTML', 'GTM-K8S2XJ4'],
  router,
  autoTracking: {
    screenview: true
  }
})

Vue.directive('observe-visibility', ObserveVisibility)

Vue.component('uhv-image-lazy-load', UHVLazyLoad)
Vue.component('uhv-media', UHVMedia)
Vue.component('uhv-image', UHVImage)
Vue.component('uhv-svg-icon', UHVIcon)
Vue.component('uhv-text', UHVText)
Vue.component('uhv-button', UHVButton)

Vue.component('uhv-content', {
  props: {
    item: { type: Object }
  },
  render: function (createElement) {
    if (this.item.type === 'img') {
      return createElement(this.item.type, { attrs: { src: this.item.content } })
    }

    if (this.item.type === 'video') {
      return createElement(
        this.item.type,
        {
          attrs: {
            src: `${this.item.content}#t=0.1`, // Load the first frame as poster
            controls: true,
            class: 'video',
            playsinline: true
          }
        })
    }

    if (this.item.type === 'h2') {
      return createElement(this.item.type, { attrs: { class: 'f-h2 f-fweight-ultra-black' } }, this.item.content)
    }

    return createElement(this.item.type, {
      domProps: {
        innerHTML: this.item.content
      }
    })
  }
})

// Hack to fix ie11 bcg bug
if (navigator.userAgent.match(/Trident\/7\./)) {
  const fixScroll = (event) => {
    event.preventDefault()
    var wd = event.wheelDelta
    var csp = window.pageYOffset
    window.scrollTo(0, csp - wd)
  }

  document.addEventListener('app:in-menu', () => {
    document.body.removeEventListener('mousewheel', fixScroll)
  })

  document.addEventListener('app:out-menu', () => {
    document.body.addEventListener('mousewheel', fixScroll)
  })

  document.body.addEventListener('mousewheel', fixScroll)
}

const app = new Vue({
  router,
  store,
  render: h => h(App)
})

document.addEventListener('DOMContentLoaded', function () {
  app.$mount('#app')

  if (window.contentIsFetched) {
    document.dispatchEvent(new CustomEvent('app:pre-rendering'))
  }
})
