<template>
  <ul class="uhv-socials f-uppercase">
    <li>
      <a href="https://www.facebook.com/ecosystem.eco" target="_blank" rel="noreferrer noopener">
        <uhv-svg-icon icon="facebook" />
      </a>
    </li>
    <li>
      <a href="https://twitter.com/ecosystem_eco" target="_blank" rel="noreferrer noopener">
        <uhv-svg-icon icon="twitter" />
      </a>
    </li>
    <li>
      <a href="https://www.linkedin.com/company/ecosystem-eco/" target="_blank" rel="noreferrer noopener">
        <uhv-svg-icon icon="linkedin" />
      </a>
    </li>
  </ul>
</template>

<script>
  import UHVSvgIcon from '@/components/svg/UHVSvgIcon'

  export default {
    name: 'UHVSocials',
    props: {},
    components: {
      'uhv-svg-icon': UHVSvgIcon
    }
  }
</script>

<style lang="scss" scoped>
  .uhv-socials {
    display: flex;
    list-style-type: none;

    li {
      margin: 0 6px;

      @include breakpoint('medium+') {
        margin: 0 9px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      a {
        display: block;

        span {
          @include size(14px);

          display: flex;

          @include breakpoint('medium+') {
            @include size(22px);
          }

          svg {
            @include size(100%);
          }
        }
      }
    }
  }
</style>
