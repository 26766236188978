var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[ "pstory", _vm.played ? 'is-played' : '', _vm.ended ? 'is-ended' : '', _vm.detailsAreOpen ? 'has-details-open' : '' ]},[_c('header',{staticClass:"header"},[_c('uhv-chevron-button',{on:{"click":function () {
      this$1.$refs.video && _vm.pause();
      _vm.$router.push({ name: 'home' });
    }}}),(_vm.Story.content)?_c('uhv-info-button',{on:{"click":function () {
        this$1.$refs.video && _vm.pause();
        if (this$1.isMobileDevice || this$1.isTabletDevice) {
          _vm.$router.push({ name: 'story-details' });
        } else {
          this$1.detailsAreOpen = true
        }
      }}}):_vm._e()],1),_c('div',{staticClass:"pstory__overlay"},[(_vm.video)?_c('uhv-play-button',{on:{"click":function($event){_vm.$refs.video.get('paused') ? _vm.play() : _vm.pause()}}}):_c('uhv-button',{attrs:{"title":_vm.$store.state.staticContent.content.taxonomies.viewMore},on:{"click":function($event){_vm.$router.push({ name: 'story-details' })}}})],1),_c('div',{staticClass:"pstory__end"},[_c('uhv-button',{attrs:{"title":_vm.$store.state.staticContent.content.taxonomies.viewMore},on:{"click":function($event){_vm.$router.push({ name: 'story-details' })}}})],1),_c('div',{staticClass:"pstory__content"},[(_vm.video)?_c('uhv-video',{ref:"video",attrs:{"autoplay":true,"video":_vm.video},on:{"click":function($event){_vm.$refs.video.get('paused') ? _vm.play() : _vm.pause()},"video:ended":_vm.end,"video:loadedmetadata":function (target) { return _vm.duration = target.duration; },"video:play":function () { return _vm.played = true; }}}):_c('img',{attrs:{"src":_vm.Story.cover_image}})],1),_c('div',{staticClass:"pstory__details"},[_c('header',{staticClass:"header"},[_c('uhv-chevron-button',{attrs:{"theme":"dark","direction":"right"},on:{"click":function($event){_vm.detailsAreOpen = false; _vm.$refs.video.play();}}})],1),_c('div',{staticClass:"pstory__details__section"},[_c('div',{staticClass:"pstory__details__index"},[_vm._v(_vm._s(_vm.index))]),_c('div',{staticClass:"o-content f-color--brand-blue"},_vm._l((_vm.Story.content),function(item,index){return _c('uhv-content',{key:index,attrs:{"item":item}})}),1)])]),(_vm.video)?_c('footer',{staticClass:"footer"},[_c('uhv-controls',{attrs:{"duration":_vm.duration,"current":_vm.currentTime},on:{"controls:muted":function (muted) { return _vm.mute(muted); }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }