<template>
  <div class="uhv-controls">

    <div class="uhv-controls__time">
      {{`${formatTime(current).minutes}:${formatTime(current).seconds}`}}
    </div>

    <div class="uhv-controls__progress" min="0" max="1" :value="current/duration" role="progressbar">
      <div class="uhv-controls__buffer" :style="`transform: scaleX(${current/duration})`"></div>
      <button
        type="button"
        :class="[`uhv-controls__breakpoint`, current/duration >= (breakpoint/duration - 0.04) ? 'is-active': '']"
        v-for="(breakpoint, index) in breakpoints"
        :key="`uhv-controls-breakpoint-${index}`"
        :style="`left: ${(breakpoint * 100) / duration}%;`"
        @click="() => $store.state.windowW > 750 && $emit('controls:breakpointclick', index, breakpoint)"
      />
    </div>

    <button type="button" class="uhv-controls__muted" @click="mute()">
      <uhv-svg-icon icon="volume-mute" v-if="muted" />
      <uhv-svg-icon icon="volume" v-else />
    </button>
  </div>
</template>

<script>
  import UHVSvgIcon from '@/components/svg/UHVSvgIcon'

  export default {
    name: 'UHVControls',
    data () {
      return {
        muted: false
      }
    },
    props: {
      current: {
        type: Number,
        default: 0
      },
      duration: {
        type: Number,
        default: 0
      },
      breakpoints: {
        type: Array,
        default: () => []
      }
    },
    components: {
      'uhv-svg-icon': UHVSvgIcon
    },
    methods: {
      formatTime (timeInSeconds) {
        const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8)

        return {
          minutes: result.substr(3, 2),
          seconds: result.substr(6, 2)
        }
      },
      mute () {
        this.muted = !this.muted
        this.$emit('controls:muted', this.muted) // eslint-disable-line vue/custom-event-name-casing
      }
    }
  }
</script>

<style lang="scss" scoped>
.uhv-controls {
  display: flex;
  align-items: center;

  &__time {
    position: relative;

    font-family: map-get($fontFamilies, 'publica-play');
    font-weight: map-get($fonts-weights, regular);
    color: map-get($colors-list, white);
    font-size: 8px;
    line-height: (8/8);
    min-width: 30px;
  }

  &__progress {
    @include size(100%, 2px);

    margin-right: 10px;
    margin-left: 10px;

    position: relative;
    border-radius: 2px;
    background-color: rgba(map-get($colors-list,white),0.3);
    flex: 1;
  }

  &__buffer {
    @include absolute(top 0 bottom 0 left 0);
    @include size(100%);

    background-color: map-get($colors-list, brand-yellow);
    transform: scaleX(0);
    transform-origin: 0 center;
  }

  &__muted {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    .icon {
      @include size(12px, 10px);

      svg {
        fill: map-get($colors-list, white);
      }
    }
  }

  &__breakpoint {
    @include absolute(top 0 bottom 0 left 0);
    @include size(6px);

    margin: auto 0 auto -1.5px;
    padding: 0;

    border-radius: 3px;
    border: none;
    background-color: map-get($colors-list, brand-yellow);

    &:focus {
      outline: none;
    }

    &::after {
      @include absolute(top 0 left 0 bottom 0 left 0);
      @include size(16px);

      display: block;
      border: 1px solid rgba(map-get($colors-list, brand-yellow), 0.5);
      border-radius: 8px;
      margin: -5px 0 0 -5px;
      // transform: scale(0);
      transform: scale(0);
      transform-origin: center;
      transition: transform 0.5s $bezier--ease-in-out;
      content: '';
    }

    &.is-active::after {
      transform: scale(1);
    }

    @include breakpoint('medium+') {
      @include size(14px);

      border-radius: 7px;
      margin-left: -3.5px;

       &::after {
         @include size(50px);

          border-radius: 25px;
          margin: -18px 0 0 -18px;
       }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
