<template lang="html">
  <section
    class="uhv-loader u-align-center"
    :class="[
      isVisible ? 'is-visible' : 'is-hidden',
      isActive ? 'is-active' : 'is-inactive'
    ]"
  >
    <uhv-svg-icon icon="ecosystem-presente" />

    <uhv-svg-icon icon="lhistoire-vrai-du-recyclage" />

    <footer class="uhv-loader__footer">
      <!-- <uhv-spinner :progress='loaderPercent' :stroke="5" :class="[ loaderIsVisible ? 'is-visible' : 'is-hidden' ]" /> -->
      <div class="uhv-spinner"></div>
      <!-- <uhv-button
        v-if="$store && $store.state && $store.state.staticContent"
        :title="$store.state.staticContent.content.taxonomies.discover"
        :class="[loaderIsVisible ? 'is-hidden' : 'is-visible']"
        @click="hideLoader"
      /> -->
    </footer>
  </section>
</template>

<script>
// Mixins
  import BaseMixin from '@/mixins/base'

  // Store
  import { GETTERS as G, MUTATIONS as M } from '@/store/helpers'

  // Data
  import loaderData from '@/data/loader'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import { sleep } from '@/helpers/globals'

  // Components
  // import UHVSpinner from '@/components/loader/UHVSpinner'
  import UHVSvgIcon from '@/components/svg/UHVSvgIcon'

  export default {
    name: 'UHVLoader',

    mixins: [BaseMixin],

    data () {
      return {
        isActive: true,
        isVisible: true,
        content: loaderData()
      }
    },

    components: {
      // 'uhv-spinner': UHVSpinner,
      'uhv-svg-icon': UHVSvgIcon
    },

    computed: {
      loaderPercent () {
        return this.$store.getters[G.loaderPercent]
      },

      loaderIsVisible () {
        return this.$store.getters[G.loaderIsVisible]
      }
    },

    watch: {
      loaderIsVisible: 'watchLoaderIsVisibleChange'
    },

    created () {
      this.startedAt = new Date()
    },

    destroyed () {},

    methods: {
      hideLoader () {
        this.isVisible = false

        EventBus.$emit('hideloader')

        setTimeout(() => {
          this.isActive = false
        }, 1000)

        return this.$store.commit(M.updateLoaderVisibility, false)
      },

      // Watchers
      async watchLoaderIsVisibleChange () {
        if (!this.loaderIsVisible) {
          const minimumToLoad = 1000 // in ms
          const now = new Date()
          const diff = now - this.startedAt
          const haveToWait = minimumToLoad - diff < 0 ? 0 : minimumToLoad - diff

          await sleep(haveToWait)

          this.hideLoader()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.uhv-loader {
  @include size(100vw, 100vh);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  transform: translateZ(0);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  opacity: 1;
  visibility: visible;
  background-color: map-get($colors-list, brand-blue);

  transition: opacity 1s $ease-out-quint, visibility 1s $ease-out-quint;
  will-change: opacity, visibility;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &.is-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.is-inactive {
    display: none;
  }

  &__footer {
    @include size(100%, 48px);

    position: relative;

    @include breakpoint("medium+") {
      height: 64px;
    }

    .btn {
      @include absolute(top 0 left 50% bottom 0);

      transform: translate3d(-50%, 0, 0);
    }

    .uhv-spinner {
      @include absolute(top 0 right 0 bottom 0 left 0);

      margin: auto;
    }
  }
}

.icon.icon--ecosystem-presente {
  margin: 0 auto;
  display: block;
  width: 110px;
  height: auto;

  max-width: 100%;
}

.icon.icon--lhistoire-vrai-du-recyclage {
  margin: 30px auto;
  display: block;

  fill: map-get($colors-list, white);
}

.uhv-spinner {
  @include size(36px);

  background-color: map-get($colors-list, brand-blue);
  animation: spinner 1.1s infinite linear;

  border-radius: 50%;
  border-top: 5px solid rgba(map-get($colors-list, white), 0.2);
  border-right: 5px solid rgba(map-get($colors-list, white), 0.2);
  border-bottom: 5px solid rgba(map-get($colors-list, white), 0.2);
  border-left: 5px solid map-get($colors-list, white);
  transform: translateZ(0);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
