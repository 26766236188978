// Store
import store from '@/store'
import { STATE as S, MUTATIONS as M, ACTIONS as A } from '@/store/helpers'

export const getStaticContent = async (to, from, next) => {
  if (!store.state[S.contentIsFetched]) {
    try {
      await fetchAllContent()

      next()
    } catch (e) {
      store.commit(M.contentIsFetched, true)

      next()

      throw Error(e)
    }
  } else {
    next()
  }
}

export const fetchAllContent = async () => {
  await store.dispatch(A.fetchStaticContent)
  store.commit(M.contentIsFetched, true)
}
