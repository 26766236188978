<template>
  <div class="uhv-orientation u-bg-color--brand-blue">
    <div>
      <uhv-svg-icon icon="logo" />
      <uhv-svg-icon icon="sync" />
      <h1 class="f-h1 f-color--white f-fweight-extra-black u-align-center f-uppercase">
        {{ $store.state.staticContent && $store.state.staticContent.content.taxonomies.turnPhone }}
      </h1>
    </div>
  </div>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import UHVSvgIcon from '@/components/svg/UHVSvgIcon'

  export default {
    name: 'UHVOrientation',

    components: {
      'uhv-svg-icon': UHVSvgIcon
    },

    computed: {
      windowH () {
        return this.$store.state[S.windowH]
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this._onResize()
    },

    beforeDestroy () {
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        let vh = this.windowH * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .uhv-orientation {
    @include fixed(top 0 right 0 bottom 0 left 0);
    @include size(100%);

    align-items: center;
    justify-content: center;

    z-index: 50;
    overflow: hidden;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width: percentage(300/667);
      margin: 0 auto;
    }

    body.is-portrait & {
      display: none;
    }

    body.is-landscape & {
      display: flex;
    }

    @include breakpoint('medium+') {
      display: none!important;
    }
  }

  .icon {
    fill: map-get($colors-list, white);
  }

  .icon.icon--logo {
    margin-bottom: 60px;
  }

  h1 {
    margin-top: 20px;
  }
</style>
