// Helpers
import { slugify } from '@/helpers/string'

export default class Story {
  constructor (params) {
    const paramsKeys = Object.keys(params)

    paramsKeys.forEach(key => {
      this[key] = params[key]
    })

    this.thumbnailAlreadyShownOnHome = false
    this.slug = slugify(this.title)
    this.videoAlreadyLoaded = false
    this.videoLoadedPercent = 0
  }
}
