<template lang="html">
  <button
    class="btn"
    type="button"
    :disabled="disabled"
    @click="$emit('click')"
  >
    {{ title }}
  </button>
</template>

<script>
  export default {
    name: 'UHVButton',

    props: {
      title: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
