<template>
  <section
    class="uhv-story-thumbnail"
    :class="elClasses"
    :style="elStyle"
  >
    <div
      class="uhv-story-thumbnail__background"
      @click="close"
    />

    <div class="uhv-story-thumbnail__wrapper">
      <div
        class="uhv-story-thumbnail__close-container"
        @click="close"
      >
        <button class="uhv-story-thumbnail__close" type="button">

          <svg class="uhv-story-thumbnail__close__spinner" :height="radius * 2" :width="radius * 2">
            <circle :stroke-width="strokeWidth" stroke="white" fill="transparent" :r="radius - strokeWidth" :cx="radius" :cy="radius" :style="`stroke-dashoffset: ${ strokeDashoffset };`"/>
            <circle :stroke-width="strokeWidth" stroke="white" opacity="0.2" fill="transparent" :r="radius - strokeWidth" :cx="radius" :cy="radius" />
          </svg>

          <uhv-svg-icon icon="times" />
        </button>
      </div>

      <div class="uhv-story-thumbnail__container">
        <header class="uhv-story-thumbnail__header">
          <div class="uhv-story-thumbnail__index">{{ zeroPad(index) }}</div>

          <div class="uhv-story-thumbnail__header__content" v-if="$store.state.windowW >= 750">
            <h1 class="f-h1 f-fweight-black f-uppercase f-color--white">{{ story.title }}</h1>
            <p
              class="uhv-story-thumbnail__short-description"
              v-html="story.short_description"
            />
          </div>

          <uhv-thumb
            @button-click="close"
            :image="story.cover_image"
            :title="$store.state.windowW >= 750 ? '' : story.title"
            :to="to"
            :iconsize="$store.state.windowW >= 750 ? 'large' : 'small'"
          />
        </header>

        <p v-if="$store.state.windowW < 750" class="uhv-story-thumbnail__short-description u-align-center" v-html="story.short_description" />

      </div>
    </div>
  </section>
</template>

<script>
  // Components
  import UHVThumb from '@/components/thumb/UHVThumb'
  import UHVSvgIcon from '@/components/svg/UHVSvgIcon'

  // Helpers
  import zeroPad from '@/helpers/zero-pad'

  // Behavior
  import RAF from '@/behavior/RAF'

  export default {
    name: 'UHVStoryThumbnail',

    data () {
      return {
        last: 320,
        strokeDashoffset: 2 * Math.PI * (this.$store.state.windowW > 750 ? 61 : 18),
        radius: this.$store.state.windowW > 750 ? 61 : 18,
        strokeWidth: this.$store.state.windowW > 750 ? 4 : 2,
        rafID: null
      }
    },

    props: {
      story: {
        type: Object,
        required: true
      },

      index: {
        type: Number,
        required: true
      },

      visible: {
        type: Boolean
      }
    },

    components: {
      'uhv-thumb': UHVThumb,
      'uhv-svg-icon': UHVSvgIcon
    },

    computed: {
      elClasses () {
        return [
          this.visible ? 'is-visible' : ''
        ]
      },

      elStyle () {
        return {}
      },

      to () {
        if (this.story.video) {
          return { name: 'story', params: { slug: this.story.slug } }
        }

        return { name: 'story-details', params: { slug: this.story.slug } }
      }
    },

    watch: {
      visible: '_onVisibleChange'
    },

    methods: {
      // Watchers
      _onVisibleChange () {
        setTimeout(() => {
          if (this.visible) {
            this.startRAF()
            this.$emit('shown')
          } else {
            this.stopRAF()
            this.last = 320
            this.$emit('closed')
          }
        }, 1250)
      },

      zeroPad (index) {
        return zeroPad(index + 1, 2)
      },

      close () {
        this.stopRAF()
        this.last = 320
        this.$emit('close')
      },

      // RAF
      rafCallback () {
        this.last = this.last - 1
        this.strokeDashoffset = (this.last * (2 * Math.PI * this.radius)) / 320

        if (this.last <= 0) {
          this.close()
        }
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .uhv-story-thumbnail {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    z-index: 25;

    width: 100%;
    height: 100%;

    visibility: hidden;

    transition-delay: 1s;

    will-change: visibility;

    &.is-visible {
      visibility: visible;

      transition-delay: 0s;
    }
  }

  .uhv-story-thumbnail__header {
    position: relative;
    margin-top: -100px;

    @include breakpoint('medium+') {
      display: flex;
      flex-direction: row;
      margin-top: 0;

      .uhv-thumb {
        margin-left:auto;
        width: percentage(583/903);
        max-width: 583px;
      }
    }
  }

  .uhv-story-thumbnail__header__content {
    @include absolute(top 50% left 0);

    margin: auto 0;
    transform: translate3d(0, -50%,0);
    pointer-events: none;

    z-index: 5;

    .f-h1 {
      max-width: percentage(513/903);
    }

    p {
      max-width: percentage(288/903);
    }
  }

  .uhv-story-thumbnail__index {
    @include letter-spacing(10);
    @include absolute(bottom 100% left 0);

    margin-bottom: -30px;
    margin-left: 20px;

    font-size: 80px;
    line-height: (109.6/80);
    color: rgba(map-get($colors-list, white), 0.4);
    font-weight: map_get($fonts-weights, 'thin');
    font-family: map-get($fontFamilies, 'publica-play' );
    pointer-events: none;

    transform: translateY(100%) translateZ(0);
    transition-delay: 0.25s;

    opacity: 0;

    transition: transform 1s, opacity 1s;
    will-change: transform, opacity;

    .uhv-story-thumbnail.is-visible & {
      transform: translateZ(0);

      opacity: 1;

      transition: transform 1s $ease-out-quint, opacity 1s $ease-out-quint;
      transition-delay: 0.7s;
    }

    @include breakpoint('medium+') {
      right: 0;
      left: auto;

      margin-right: 20px;
      margin-bottom: -60px;
      margin-left: 0;

      font-size: 150px;
      line-height: (205.5/150);
    }
  }

  .uhv-story-thumbnail__short-description {
    margin: 20px 40px 0 40px;

    @include breakpoint('medium+') {
      margin: 20px 0 0 0;
      color: map-get($colors-list, white);
      font-size: 16px;
      line-height: (24/16);
    }
  }

  .uhv-story-thumbnail__background {
    @include size(100%);
   @include absolute(top 0 right 0 bottom 0 left 0);

    transform: translateZ(0);
    z-index: 1;

    background-color: rgba(map-get($colors-list, black), 0.65);
    opacity: 0;

    transition: opacity 1s $ease-out-quint;
    transition-delay: 0.25s;
    will-change: opacity;

    .uhv-story-thumbnail.is-visible & {
      opacity: 1;
      transition-delay: 0s;
    }
  }

  .uhv-story-thumbnail__wrapper {
    @include absolute(top 0 right 0 bottom 0 left 0);
    @include size(100%);

    transform: translateY(100%) translateZ(0);
    z-index: 2;

    display: flex;
    flex-direction: column;

    opacity: 0;

    transition: transform 1s, opacity 1s;
    transition-delay: 0s;
    will-change: transform, opacity;

    .uhv-story-thumbnail.is-visible & {
      transform: translateZ(0);

      opacity: 1;

      transition: transform 1s $ease-out-quint, opacity 1s $ease-out-quint;
      transition-delay: 0.25s;
    }
  }

  .uhv-story-thumbnail__close-container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  .uhv-story-thumbnail__close {
    @include size(36px);

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    -webkit-appearance: none;
    border: 0;
    background-color: transparent;

    opacity: 0;

    transition: opacity 1s $ease-out-quint 1s;
    will-change: opacity;
    transition-delay: 0s;
    z-index: 10;

    &:focus {
      outline: none;
    }

    .icon.icon--times {
      @include size(8px);

      svg {
        @include size(100%);
      }

      @include breakpoint('medium+') {
        @include size(24px);
      }
    }

    .uhv-story-thumbnail.is-visible & {
      opacity: 1;
      transition-delay: 1s;

    }

    @include breakpoint('medium+') {
      @include size(122px);

      transform: translate3d(-100%,0,0);
    }

    &__spinner {
      @include absolute(top 0 right 0 bottom 0 left 0);

      z-index: 1;

      circle:first-child {
        stroke: map-get($colors-list, brand-yellow);
        stroke-dasharray: 2 * 3.1415 * 18;
        stroke-dashoffset: 2 * 3.1415 * 18;

        @include breakpoint('medium+') {
          stroke-dasharray: 2 * 3.1415 * 61;
          stroke-dashoffset: 2 * 3.1415 * 61;
        }
      }
    }
  }

  .uhv-story-thumbnail__container {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    margin-right: auto;
    margin-left: auto;

    width: percentage(335/375); // From design
    padding: 0 20px 30px 20px;
    max-width: 583px;

    background-color: map-get($colors-list, white);

     @include breakpoint('medium+') {
       transform: translate3d(0, calc(-50% - 61px),0);
       padding: 0;
       background-color: rgba(map-get($colors-list, white), 0);
       max-width: 903px;
     }
  }
</style>
