<template>
  <section
    class="uhv-menu"
    :class="elClasses"
    :style="elStyle"
  >
    <div class="uhv-menu__section" ref="section">
      <header class="header f-h1 f-color--black">
        <div></div>
        <!-- <uhv-chevron-button @click="closeMenu" direction="down" /> -->
        <uhv-socials />
      </header>

      <div class="uhv-menu__body">
        <a href="https://www.ecosystem.eco/" target="_blank" rel="noreferrer noopener">
          <uhv-svg-icon icon="colored-logo" />
        </a>
        <uhv-svg-icon icon="lhistoire-vrai-du-recyclage" />
        <p class="uhv-menu__description u-align-center" v-html="$store.state && $store.state.staticContent && $store.state.staticContent.content.menu.description"/>

        <div class="uhv-thumb">
          <video :src="`${$store.state.staticContent.content.mainVideo.desktop}#t=0.1`" controls :cover="$store.state.staticContent.content.mainCoverImage"/>
        </div>

        <div class="uhv-menu__thumbs">
          <div class="uhv-menu__row" v-for="(story, index) in Stories"  :key="`menu-row-${index}`" :class="[ !story.video ? 'has-not-video' : '' ]" :ref="`menu-row-${index}`">
              <div v-if="$store.state.windowW >= 750" class="uhv-menu__column">
                <h1 class="f-h1 f-fweight-black f-uppercase f-color--brand-blue">{{ story.title }}</h1>
                <p class="uhv-menu__short-description" v-html="story.short_description" />
                <div class="uhv-menu__index f-thin">{{zeroPad(index + 1)}}</div>
              </div>

              <div v-if="story.video && story.content_type === 'article'" class="uhv-menu__column">
                <uhv-thumb
                  @button-click="closeMenu"
                  :iconsize="$store.state.windowW >= 750 ? 'large' : 'small'"
                  :image="story.cover_image"
                  :title="$store.state.windowW >= 750 && story.video ? '' : story.title"
                  :to="story.video ? { name: 'story', params: { slug: story.slug } } : { name: 'story-details', params: { slug: story.slug } }"
                >
                  <uhv-button v-if="!story.video" :title="$store.state.staticContent.content.taxonomies.viewContent" />
                </uhv-thumb>
              </div>
          </div>
        </div>
      </div>

      <div class="uhv-menu__footer u-align-center">
        © Copyright 2020 — <a href="https://www.ecosystem.eco/" target="_blank" rel="noopener noreferrer">ecosystem</a> — built by <a href="https://socialclubparis.com/" target="_blank" rel="noopener noreferrer">Socialclub</a>
      </div>
    </div>

  </section>
</template>

<script>
  // Store
  import { STATE as S, MUTATIONS as M, GETTERS as G } from '@/store/helpers'

  // Helpers
  import { debounce, sleep } from '@/helpers/globals'
  import { getAbsoluteBoundingRect } from '@/helpers/dom'

  // Components
  import UHVChevronButton from '@/components/button/UHVChevronButton'
  import UHVSvgIcon from '@/components/svg/UHVSvgIcon'
  import UHVThumb from '@/components/thumb/UHVThumb'
  import UHVSocials from '@/components/socials/UHVSocials'
  import UHVButton from '@/components/button/UHVButton'

  import zeroPad from '@/helpers/zero-pad'

  export default {
    name: 'UHVMenu',

    data () {
      return {
        RECTS: []
      }
    },

    components: {
      'uhv-chevron-button': UHVChevronButton,
      'uhv-svg-icon': UHVSvgIcon,
      'uhv-thumb': UHVThumb,
      'uhv-socials': UHVSocials,
      'uhv-button': UHVButton
    },

    computed: {
      elClasses () {
        return [
          this.open ? 'is-open' : ''
        ]
      },

      elStyle () {
        return {}
      },

      open () {
        return this.$store.state[S.menuIsOpen]
      },

      Stories () {
        return this.$store.state.staticContent.stories
      },

      lastStorySlugOpened () {
        return this.$store.state[G.lastStorySlugOpened]
      }
    },

    mounted () {
      if (this.lastStorySlugOpened) {
        this.setScroll()
      }
    },

    beforeDestroy () {

    },

    methods: {
      // Menu
      closeMenu () {
        this.$emit('close')
        this.$store.commit(M.updateMenuIsOpen, false)
      },

      zeroPad (index) {
        return zeroPad(index, 2)
      },

      async setScroll () {
        await sleep(100)

        this.Stories.forEach((Story, index) => {
          if (Story.slug === this.lastStorySlugOpened) {
            const ref = `menu-row-${index}`
            if (this.$refs[ref]) {
              const _$ref = this.$refs[ref]

              if (_$ref && _$ref.length > 0) {
                const $ref = _$ref[0]
                const rect = getAbsoluteBoundingRect($ref)
                console.log(rect)

                if (rect && rect.top) {
                  window.scrollTo({
                    top: rect.top - 32,
                    behavior: 'smooth'
                  })
                }
              }
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .uhv-menu {
    // @include fixed(top 0 right 0 bottom 0 left 0);
    // @include size(100%);

    padding: 32px;

    transform: translateY(100%) translateZ(0);
    z-index: 30;

    background-color: white;

    transition: transform 1s $ease-out-quint;
    will-change: transform;

    background-image: url(../../assets/icons/e.svg);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: percentage(480/1440) auto;

    &.is-open {
      transform: translateZ(0);
    }

    &__section {
      margin: 0 auto;
      max-width: $max-width;
    }

    &__description {
      @include letter-spacing(10);

      margin: 40px 0;

      font-size: #{(16/12)}em;
      line-height: (16/12);
    }

    &__index {
      @include letter-spacing(10);
      @include absolute(right 0 bottom 0);

      font-size: 150px;
      line-height: (205.5/150);
      font-family: map-get($fontFamilies, 'publica-play' );
      color: rgba(map-get($colors-list, brand-blue), 0.05);
      transform: translate3d(0,50%,0);
    }

    // &__body {}

    &__footer {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    &__thumbs {
      margin: 20px 0;

      .uhv-thumb {
        margin: 20px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__row {
      margin-top: 20px;
      margin-bottom: 20px;

      @include breakpoint('medium+') {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-top: 140px;
        margin-bottom: 140px;
        margin-right: -10px;
        margin-left: -10px;

        &.has-not-video {
          justify-content: center;

          & .uhv-menu__column {
            margin-left: 0 !important;
            text-align: center;
          }

          .uhv-menu__short-description {
            text-align: center !important;
          }
        }

        &:nth-child(even) {
          .uhv-menu__short-description {
            text-align: right;
          }

          .uhv-menu__column:first-child {
            flex: 0 0 percentage(5/12);
            max-width: percentage(5/12);
            margin-left: percentage(1/12);

            order: 1;
          }

          .uhv-menu__column:last-child {
            flex: 0 0 50%;
            max-width: 50%;
            order: 0;
          }
        }

        &:nth-child(odd) {
          .uhv-menu__short-description {
            text-align: left;
          }

          .uhv-menu__column:first-child {
            flex: 0 0 percentage(5/12);
            max-width: percentage(5/12);
          }

          .uhv-menu__column:last-child {
            margin-left: percentage(1/12);
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
      }
    }

    @include breakpoint('medium+') {
      &__column {
        position: relative;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
      }

      &__short-description {
        margin-top: 40px;
        font-size: #{(16/12)}em
      }
    }
  }

  // .uhv-menu__section {
  //   height: 10000px;
  // }

  .icon.icon--lhistoire-vrai-du-recyclage {
    margin: 0 auto;
    fill: map-get($colors-list, 'brand-blue');
  }

  .icon.icon--colored-logo {
    margin: 40px auto;
    display: block;
  }
</style>
