// Dependencies
import axios from 'axios'

// Models
import Story from '@/models/Story'

export const fetchStaticContent = async () => {
  try {
    const content = await getStaticContent()

    return content
  } catch (e) {
    throw e.response
  }
}

const getStaticContent = async () => {
  const content = {}
  const promises = [
    axios.get(`${process.env.VUE_APP_STATIC_BASE_URL}/static/json/content.json`),
    axios.get(`${process.env.VUE_APP_STATIC_BASE_URL}/static/json/stories.json`)
  ]

  return axios.all(promises)
    .then(results => {
      results.forEach(res => {
        const name = res.config.url.split('/static/json/')[1].replace('.json', '')

        if (name === 'stories') {
          const stories = res.data.map(item => new Story(item))
          res.data = stories
        }

        content[name] = res.data
      })

      return content
    })
}
