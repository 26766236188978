<template lang="html">
  <button
    :class="['btn-rounded', `is-${theme}`]"
    type="button"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <uhv-svg-icon :icon="`chevron-${direction}`" />
  </button>
</template>

<script>
  import UHVSvgIcon from '@/components/svg/UHVSvgIcon'

  export default {
    name: 'UHVChevronButton',
    components: {
      'uhv-svg-icon': UHVSvgIcon
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      theme: {
        type: String,
        default: 'light'
      },
      direction: {
        type: String,
        default: 'left'
      }
    }
  }
</script>
