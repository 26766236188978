<template>
  <router-link :to="to" class="uhv-thumb has-overlay" @click="handleClick">
    <div class="uhv-thumb__body u-pad-a-3">
      <slot>
        <h3 v-if="title" class="f-color--white f-fweight-extra-black f-uppercase f-h1">{{ title }}</h3>
        <uhv-play-button v-if="to.name === 'story'" :size="iconsize" />
      </slot>
    </div>
    <uhv-image v-if="image" :name="image" />
  </router-link>
</template>

<script>
  import UHVPlayButton from '@/components/button/UHVPlayButton'
  import UHVImage from '@/components/media/UHVImage'

  export default {
    name: 'UHVThumb',
    props: {
      title: {
        type: String,
        default: ''
      },
      image: {
        type: String
      },
      to: {
        type: Object,
        default: () => ({
          name: 'home'
        })
      },
      iconsize: {
        type: String,
        default: 'small'
      }
    },
    components: {
      'uhv-play-button': UHVPlayButton,
      'uhv-image': UHVImage
    },

    methods: {
      handleClick (event) {
        event.preventDefault()
      }
    }
  }
</script>

<style lang="scss">
.uhv-thumb {
  position: relative;

  display: flex;
  align-content: center;
  justify-content: center;

  width: 100%;
  max-height: 610px;
  border-radius: 5px;

  overflow: hidden;
  background-color: map-get($colors-list, brand-blue);

  &::before {
    display: block;
    content: '';

    padding-bottom: percentage(167.83/307); // From design

    @include breakpoint('medium+') {
      padding-bottom: percentage(610/1200);
    }
  }

  &.ihas-overlay::after {
    @include absolute(top 0 right 0 bottom 0 left 0);

    background-color: rgba(map-get($colors-list, black), 0.2);
    content: '';
    z-index: 2;
  }

  &__body {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    // white-space: nowrap;

    width: 100%;
    z-index: 3;
  }

  video,
  img {
    @include absolute(top 0 right 0 bottom 0 left 0);
    @include size(100%);

    object-fit: cover;
    object-position: center;
    z-index: 2;
  }
}
</style>
