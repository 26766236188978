// Store
import store from '@/store'
import { GETTERS as G, MUTATIONS as M } from '@/store/helpers'

export const getDeviceInformations = (to, from, next) => {
  const Support = store.getters[G.support]

  const isTablet = store.getters[G.isTabletDevice]
  const isMobile = store.getters[G.isMobileDevice]

  if (Support.android || Support.ios || Support.iphone || Support.ipad || Support.ipod) {
    store.commit(M.videoFormatNameToLoad, 'mobile')
  }

  if (isMobile || (Support.touch && isTablet)) {
    store.commit(M.videoFormatNameToLoad, 'mobile')
  }

  next()
}
