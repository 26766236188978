<template>
  <div :class="[ `pstory`, played ? 'is-played' : '', ended ? 'is-ended' : '', detailsAreOpen ? 'has-details-open' : '' ]">

    <header class="header">
      <uhv-chevron-button @click="() => {
        this.$refs.video && pause();
        $router.push({ name: 'home' });
      }" />
      <uhv-info-button
        @click="() => {
          this.$refs.video && pause();
          if (this.isMobileDevice || this.isTabletDevice) {
            $router.push({ name: 'story-details' });
          } else {
            this.detailsAreOpen = true
          }
        }"
        v-if="Story.content"
      />
    </header>

    <div class="pstory__overlay" >
      <uhv-play-button v-if="video" @click="$refs.video.get('paused') ? play() : pause()" />
      <uhv-button v-else @click="$router.push({ name: 'story-details' })" :title="$store.state.staticContent.content.taxonomies.viewMore"/>
    </div>

    <div class="pstory__end">
      <uhv-button @click="$router.push({ name: 'story-details' })" :title="$store.state.staticContent.content.taxonomies.viewMore"/>
    </div>

    <div class="pstory__content">
      <uhv-video
        :autoplay="true"
        v-if="video"
        :video="video"
        ref="video"
        @click="$refs.video.get('paused') ? play() : pause()"
        @video:ended="end"
        @video:loadedmetadata="(target) => duration = target.duration"
        @video:play="() => played = true"
      />
      <img v-else :src="Story.cover_image" />
    </div>

    <div class="pstory__details">
      <header class="header">
        <uhv-chevron-button @click="detailsAreOpen = false; $refs.video.play();" theme="dark" direction="right" />
      </header>

      <div class="pstory__details__section">
        <div class="pstory__details__index">{{ index }}</div>

        <div class="o-content f-color--brand-blue">
          <uhv-content :item="item" v-for="(item, index) in Story.content" :key="index"/>
        </div>
      </div>
    </div>

    <footer class="footer" v-if="video">
      <uhv-controls @controls:muted="(muted) => mute(muted)" :duration="duration" :current="currentTime" />
    </footer>
  </div>
</template>

<script>
  // Store
  import { STATE as S, GETTERS as G, MUTATIONS as M } from '@/store/helpers'

  // Behavior
  import RAF from '@/behavior/RAF'

  // Mixins
  import PageMixin from '@/mixins/page'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import zeroPad from '@/helpers/zero-pad'

  // Components
  import UHVVideo from '@/components/media/UHVVideo'
  import UHVChevronButton from '@/components/button/UHVChevronButton'
  import UHVInfoButton from '@/components/button/UHVInfoButton'
  import UHVPlayButton from '@/components/button/UHVPlayButton'
  import UHVControls from '@/components/controls/UHVControls'

  export default {
    name: 'PStory',
    pageName: 'story',
    mixins: [PageMixin],

    data () {
      return {
        played: false,
        ended: false,
        duration: 0,
        currentTime: 0,
        detailsAreOpen: false
      }
    },

    computed: {
      Story () {
        return this.$store.state.staticContent.stories.filter(story => story.slug === this.$route.params.slug)[0]
      },

      index () {
        return zeroPad(this.$store.state.staticContent.stories.findIndex(story => story.slug === this.$route.params.slug) + 1, 2)
      },

      Slug () {
        return this.$route.params.slug
      },

      videoFormatNameToLoad () {
        return this.$store.state[S.videoFormatNameToLoad]
      },

      video () {
        if (this.Story.video) {
          return {
            src: {
              mp4: this.Story.video[this.videoFormatNameToLoad]
            },
            isFullScreen: true
          }
        }

        return false
      },

      isMobileDevice () {
        return this.$store.getters[G.isMobileDevice]
      },

      isTabletDevice () {
        return this.$store.getters[G.isTabletDevice]
      },

      windowH () {
        return this.$store.state[S.windowH]
      }
    },

    components: {
      'uhv-play-button': UHVPlayButton,
      'uhv-chevron-button': UHVChevronButton,
      'uhv-info-button': UHVInfoButton,
      'uhv-video': UHVVideo,
      'uhv-controls': UHVControls
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)

      if (this.$route.query.d && this.$route.query.d === 1) {
        this.detailsAreOpen = true
        this.$router.replace({ name: 'story', params: { slug: this.Slug } })
      }

      this.$store.commit(M.updateLastStorySlugOpened, this.Slug)
    },

    mounted () {
      this.$nextTick(() => {
        this.startRAF()

        this._onResize()

        if (this.detailsAreOpen) {
          this.$refs.video.pause()
        }
      })
    },

    detroyed () {
      // Events
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        let vh = this.windowH * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      },

      // RAF
      rafCallback () {
        if (this.$refs.video && !this.$refs.video.get('paused')) {
          this.currentTime = this.$refs.video.get('currentTime')
        }
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      },

      pause () {
        this.$refs.video.pause()
        this.played = false
      },

      play () {
        this.$refs.video.play()
        this.played = true
      },

      end (event) {
        this.played = false
        this.ended = true
      },

      mute (muted) {
        this.$refs.video.set('muted', muted)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pstory {
    position: relative;
    z-index: 2;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: white;
    overflow: hidden;

    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;

    & .header {
      top: 0;
    }
  }

  .pstory__overlay {
    @include absolute(top 0 right 0 bottom 0 left 0);
    @include auto-alpha(1);

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(map-get($colors-list, black), 0.7);

    transition: opacity 1s $ease-out-quint, visibility 1s $ease-out-quint;
    will-change: opacity, visibility;

    z-index: 2;

    .pstory.is-played &,
    .pstory.is-ended & {
      @include auto-alpha(0);
    }
  }

  .pstory__details {
    @include absolute(top 0 right 0 bottom 0);
    transform: translateX(100%) translateZ(0);
    z-index: 21;

    width: 50%;
    height: 100%;
    padding: 45px 90px;

    background-color: white;
    overflow-y: auto;

    transition: transform 0.55s $ease-out-quint;
    will-change: transform;

    @include breakpoint('large-') {
      width: 100%;
    }

    .pstory.has-details-open & {
      transform: translateX(0) translateZ(0);
    }

    &__section {
      margin-bottom: 50px;

      @include breakpoint('medium+') {
        margin: 160px auto 0 auto;
      }
    }

    &__index {
      @include letter-spacing(10);

      font-size: 80px;
      line-height: (109.6/80);
      color: map-get($colors-list, brand-blue);
      opacity: 0.1;
      font-weight: map-get($fonts-weights, light);
      font-family: map-get($fontFamilies, 'publica-play');
    }
  }

  .pstory__end {
    @include absolute(top 0 right 0 bottom 0 left 0);
    @include auto-alpha(0);

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(map-get($colors-list, black), 0.7);

    transition: opacity 1s $ease-out-quint, visibility 1s $ease-out-quint;
    will-change: opacity, visibility;

    z-index: 2;

    .pstory.is-ended & {
      @include auto-alpha(1);
    }
  }

  .pstory__content {
    @include size(100%);
    @include absolute(top 0 right 0 bottom 0 left 0);

    overflow: hidden;
    z-index: 1;

    img {
      @include size(100%);

      object-fit: cover;
      object-position: center;
    }
  }
</style>
