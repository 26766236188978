<template lang="html">
  <div
    :class="[`uhv-play`, `uhv-play--${size}`]"
    type="button"
    @click="$emit('click')"
  >
    <uhv-svg-icon icon="play" />
  </div>
</template>

<script>
  import UHVSvgIcon from '@/components/svg/UHVSvgIcon'

  export default {
    name: 'UHVPlayButton',
    components: {
      'uhv-svg-icon': UHVSvgIcon
    },

    props: {
      /**
       * Size of the icon
       * @param {String} - size
       **/
      size: {
        type: String,
        default: 'large'
      }
    }
  }
</script>

<style lang="scss" scoped>
.uhv-play {
  @include size(112px);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 56px;
  min-width: 112px;

  background-color: map-get($colors-list, brand-yellow);

  &.uhv-play--small {
    @include size(36px);

    border-radius:18px;
    min-width: 36px;

    .icon {
      @include size(11px);
    }
  }
}

</style>
