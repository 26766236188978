<template>
  <div class="pstory-details">
    <header class="header">
      <uhv-chevron-button @click="$router.push({ name: 'story', params: { slug: Slug } })" theme="dark" direction="down" />
    </header>

    <div class="pstory-details__section">
      <div class="pstory-details__index">{{ index }}</div>

      <div class="o-content f-color--brand-blue">
        <uhv-content :item="item" v-for="(item, index) in story.content" :key="index"/>
      </div>
    </div>
  </div>
</template>

<script>
  // Store
  import { STATE as S, GETTERS as G } from '@/store/helpers'

  // Mixins
  import PageMixin from '@/mixins/page'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import zeroPad from '@/helpers/zero-pad'

  // Components
  import UHVChevronButton from '@/components/button/UHVChevronButton'

  export default {
    components: {
      'uhv-chevron-button': UHVChevronButton
    },
    name: 'PStoryDetails',
    pageName: 'story-details',
    mixins: [PageMixin],

    created () {
      // Events
      EventBus.$on('resize', this._onResize)

      if (!this.isMobileDevice && !this.isTabletDevice) {
        this.$router.push({ name: 'story', params: { slug: this.Slug }, query: { d: 1 } })
      }
    },

    computed: {
      Slug () {
        return this.$route.params.slug
      },

      story () {
        return this.$store.state.staticContent.stories.filter(story => story.slug === this.Slug)[0]
      },
      index () {
        return zeroPad(this.$store.state.staticContent.stories.findIndex(story => story.slug === this.Slug) + 1, 2)
      },
      windowH () {
        return this.$store.state[S.windowH]
      },
      isMobileDevice () {
        return this.$store.getters[G.isMobileDevice]
      },
      isTabletDevice () {
        return this.$store.getters[G.isTabletDevice]
      }
    },

    mounted () {
      this._onResize()
    },

    detroyed () {
      // Events
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        let vh = this.windowH * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pstory-details {
    position: relative;
    z-index: 3;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: white;

    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;

    overflow-y: auto;

    &__section {
      margin-bottom: 50px;

      @include breakpoint('medium+') {
        margin: 160px auto 0 auto;
        max-width: percentage(794/1440);
      }
    }

    &__index {
      @include letter-spacing(10);

      font-size: 80px;
      line-height: (109.6/80);
      color: map-get($colors-list, brand-blue);
      opacity: 0.1;
      font-weight: map-get($fonts-weights, light);
      font-family: map-get($fontFamilies, 'publica-play');
    }
  }
</style>
