export const STATE = {
  // Device informations
  support: 'support',
  isTouchDevice: 'isTouchDevice',
  isRetina: 'isRetina',
  windowW: 'windowW',
  windowH: 'windowH',
  pageHeight: 'pageHeight',
  canAutoPlayVideo: 'canAutoPlayVideo',
  videoFormatNameToLoad: 'videoFormatNameToLoad',
  // User preferences
  lang: 'lang',
  direction: 'direction',
  mediaQuery: 'mediaQuery',
  enableLazyLoad: 'enableLazyLoad',
  colorScheme: 'colorScheme',
  // Events
  gtmEventsSent: 'gtmEventsSent',
  loaderIsVisible: 'loaderIsVisible',
  loaderPercent: 'loaderPercent',
  hasLoader: 'hasLoader',
  mainVideoCurrentTime: 'mainVideoCurrentTime',
  mainVideoIsLoaded: 'mainVideoIsLoaded',
  // Data
  images: 'images',
  globalData: 'globalData',
  contentIsFetched: 'contentIsFetched',
  staticContent: 'staticContent',
  numberOfContentFetched: 'numberOfContentFetched',
  menuIsOpen: 'menuIsOpen',
  lastStorySlugOpened: 'lastStorySlugOpened'
}

export const GETTERS = {
  // Device informations
  support: 'support',
  isTabletDevice: 'isTabletDevice',
  isMobileDevice: 'isMobileDevice',
  isGtmEventSent: 'isGtmEventSent',
  deviceOrientation: 'deviceOrientation',
  // User preferences
  lang: 'lang',
  colorScheme: 'colorScheme',
  // Data
  images: 'images',
  globalData: 'globalData',
  loaderPercent: 'loaderPercent',
  loaderIsVisible: 'loaderIsVisible',
  lastStorySlugOpened: 'lastStorySlugOpened'
}

export const MUTATIONS = {
  // Device informations
  support: 'M_support',
  windowW: 'M_windowW',
  windowH: 'M_windowH',
  updateDeviceOrientation: 'M_updateDeviceOrientation',
  pageHeight: 'M_pageHeight',
  autoplayVideo: 'M_autoplayVideo',
  videoFormatNameToLoad: 'M_videoFormatNameToLoad',
  // User preferences
  lang: 'M_lang',
  direction: 'M_direction',
  mediaQuery: 'M_mediaQuery',
  enableLazyLoad: 'M_ennableLazyLoad',
  colorScheme: 'M_colorScheme',
  // Events
  sendGtmEvent: 'M_sendGtmEvent',
  updateHasLoader: 'M_updateHasLoader',
  updateLoaderVisibility: 'M_updateLoaderVisibility',
  updateLoaderPercent: 'M_updateLoaderPercent',
  updateMainVideoIsLoaded: 'M_updateMainVideoIsLoaded',
  updateMainVideoCurrentTime: 'M_updateMainVideoCurrentTime',
  // Data
  images: 'M_images',
  globalData: 'M_globalData',
  contentIsFetched: 'M_contentIsFetched',
  staticContent: 'M_staticContent',
  numberOfContentFetched: 'M_numberOfContentFetched',
  updateMenuIsOpen: 'M_updateMenuIsOpen',
  updateLastStorySlugOpened: 'M_lastStorySlugOpened'
}

export const ACTIONS = {
  fetchStaticContent: 'fetchStaticContent'
}
