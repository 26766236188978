<template>
  <div :class="[ `phome`, ended ? 'is-ended' : '', paused && ! hasThumbnail ? 'is-paused' : '' ]">
    <!-- <header
      class="header f-h1 f-color--white"
    >
      <a href="https://www.ecosystem.eco/" target="_blank" rel="noopener noreferrer">
        <uhv-svg-icon icon="logo" />
      </a>
      <uhv-bars-button @click="openMenu" />
    </header> -->

    <!-- <div class="phome__video">
      <uhv-video
        :video="video"
        ref="video"
        @video:play="play"
        @video:pause="pause"
        @video:ended="end"
        @video:canplay="canplay"
        @click="$refs.video.get('paused') ? $refs.video.play() : $refs.video.pause()"
        @video:loadedmetadata="(target) => duration = target.duration"
      />
    </div> -->

    <!-- <div class="phome__overlay">
      <uhv-button
        v-if="ended"
        @click="replay"
        :title="$store.state.staticContent.content.taxonomies.replayVideo"
      />
      <uhv-play-button
        v-if="paused"
        @click="() => {
          play();
          $refs.video.play();
        }"
      />
    </div> -->

    <!-- <footer class="footer" >
      <uhv-controls
        @controls:muted="(muted) => mute(muted)"
        @controls:breakpointclick="(breakpoint, currentTime) => {
          $store.commit('M_updateMainVideoCurrentTime', currentTime)
          $refs.video.set('currentTime', currentTime)
          stories.forEach(story => (story.thumbnailAlreadyShownOnHome = false))
          setCurrentThumbnail(stories[breakpoint], breakpoint)
        }"
        :duration="duration"
        :current="mainVideoCurrentTime"
        :breakpoints="breakpoints"
      />
    </footer> -->

    <uhv-menu />

    <!-- <uhv-story-thumbnail
      v-for="(story, storyIndex) in content.stories"
      :key="`phome_story_thumbnail-${storyIndex}`"
      :story="story"
      :index="storyIndex"
      :visible="storyIndex === currentThumbnailIndex"
      @shown="thumbnailIsShown"
      @close="closeThumbnail"
      @closed="thumbnailIsClosed"
    >
    </uhv-story-thumbnail> -->
  </div>
</template>

<script>
  // Mixins
  import PageMixin from '@/mixins/page'

  // Behavior
  import RAF from '@/behavior/RAF'

  // Store
  import { STATE as S, MUTATIONS as M } from '@/store/helpers'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import UHVVideo from '@/components/media/UHVVideo'
  import UHVMenu from '@/components/menu/UHVMenu'
  import UHVStoryThumbnail from '@/components/story/UHVStoryThumbnail'
  import UHVBarsButton from '@/components/button/UHVBarsButton'
  import UHVSvgIcon from '@/components/svg/UHVSvgIcon'
  import UHVControls from '@/components/controls/UHVControls'
  import UHVPlayButton from '@/components/button/UHVPlayButton'

  export default {
    name: 'PHome',
    pageName: 'home',
    mixins: [PageMixin],

    components: {
      'uhv-video': UHVVideo,
      'uhv-menu': UHVMenu,
      'uhv-story-thumbnail': UHVStoryThumbnail,
      'uhv-bars-button': UHVBarsButton,
      'uhv-svg-icon': UHVSvgIcon,
      'uhv-controls': UHVControls,
      'uhv-play-button': UHVPlayButton
    },

    data () {
      return {
        currentThumbnailIndex: null,
        hasThumbnail: false,
        duration: 0,
        ended: false,
        paused: false,
        videoAlreadyPlayed: false,
        rafID: null
      }
    },

    computed: {
      content () {
        return this.$store.state[S.staticContent]
      },

      stories () {
        return this.content.stories
      },

      videoFormatNameToLoad () {
        return this.$store.state[S.videoFormatNameToLoad]
      },

      mainVideoCurrentTime () {
        return this.$store.state[S.mainVideoCurrentTime]
      },

      loaderIsVisible () {
        return this.$store.state[S.loaderIsVisible]
      },

      hasLoader () {
        return this.$store.state[S.hasLoader]
      },

      video () {
        return {
          src: {
            mp4: this.content.content.mainVideo[this.videoFormatNameToLoad]
          },
          isFullScreen: true

        }
      },

      breakpoints () {
        return this.stories.map((story) => story.timeOfBreakpoint)
      },

      windowH () {
        return this.$store.state[S.windowH]
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
      // EventBus.$once('hideloader', () => {
      //   setTimeout(() => {
      //     this.$refs.video.play()
      //   }, 500)
      // })
    },

    mounted () {
      this.$nextTick(() => {
        if (this.$refs.video) {
          this.$refs.video.load()
          this.$refs.video.pause()
        }

        this._onResize()

        this.startRAF()
      })
    },

    beforeDestroy () {
      EventBus.$off('resize', this._onResize)

      this.stopRAF()
    },

    methods: {
      // Events
      _onResize () {
        // let vh = this.windowH * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        // this.$el.style.setProperty('--vh', `${vh}px`)
      },

      // RAF
      rafCallback () {
        if (this.$refs.video && !this.$refs.video.get('paused') && this.stories) {
          const currentTime = this.$refs.video.get('currentTime')

          this.$store.commit(M.updateMainVideoCurrentTime, currentTime)

          this.stories.forEach((story, storyIndex) => {
            if (currentTime >= story.timeOfBreakpoint - (story.timeOfBreakpoint / 100) && currentTime <= story.timeOfBreakpoint + (story.timeOfBreakpoint / 100) && !story.thumbnailAlreadyShownOnHome) {
              this.setCurrentThumbnail(story, storyIndex)
            }
          })
        }
      },

      setCurrentThumbnail (story, index) {
        this.$refs.video.pause()
        this.currentThumbnailIndex = index
        this.hasThumbnail = true
        story.thumbnailAlreadyShownOnHome = true
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      },

      // Menu
      openMenu () {
        this.$refs.video && this.$refs.video.pause()
        this.$store.commit(M.updateMenuIsOpen, true)
      },

      // Stories
      thumbnailIsShown () {

      },

      closeThumbnail () {
        this.currentThumbnailIndex = null
      },

      thumbnailIsClosed () {
        // this.$refs.video.play()
        this.hasThumbnail = false
      },

      replay () {
        this.stories.forEach((story) => {
          this.currentThumbnailIndex = null
          story.thumbnailAlreadyShownOnHome = false
        })

        this.ended = false
        this.$refs.video.set('currentTime', 0)
        // this.$refs.video.play()
      },

      // Video
      play (event) {
        this.ended = false
        this.paused = false
      },

      canplay () {
        if (!this.hasLoader && !this.videoAlreadyPlayed) {
          this.videoAlreadyPlayed = true
          this.$refs.video.set('currentTime', this.mainVideoCurrentTime)
          // this.$refs.video.play()
        }
      },

      pause () {
        this.paused = true
      },

      end () {
        this.ended = true
      },
      mute (muted) {
        this.$refs.video.set('muted', muted)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .phome {
    position: relative;
    z-index: 1;

    // height: 100vh;
    // height: calc(var(--vh, 1vh) * 100);
    // overflow: hidden;

    // padding-top: 30px;
    // padding-right: 20px;
    // padding-bottom: 20px;
    // padding-left: 20px;
  }

  .header {
    .icon.icon--logo {
      fill: map-get($colors-list, white);
    }
  }

  .phome__overlay {
    @include absolute(top 0 right 0 bottom 0 left 0);
    @include auto-alpha(0);

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(map-get($colors-list, black), 0.7);

    transition: opacity 1s $ease-out-quint, visibility 1s $ease-out-quint;
    will-change: opacity, visibility;

    z-index: 2;

    .phome.is-ended & {
      @include auto-alpha(1);
    }

    .phome.is-paused & {
      @include auto-alpha(1);
    }
  }

  .phome__video {
    @include size(100%);
    @include absolute(top 0 right 0 bottom 0 left 0);

    overflow: hidden;
    z-index: 1;
  }

  .icon.icon--logo {
    svg {
      @include size(100%);
    }

    @include breakpoint('medium+') {
      @include size(160px, 20px)
    }
  }
</style>
