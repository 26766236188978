// Dependencies
import axios from 'axios'

// Store
import store from '@/store'
import { STATE as S, MUTATIONS as M } from '@/store/helpers'

// Helpers
import { isPrerendering } from '@/helpers/globals'

export const loadMainVideo = async (to, from, next) => {
  if (!store.state[S.mainVideoIsLoaded] && !isPrerendering) {
    const videoFormatNameToLoad = store.state[S.videoFormatNameToLoad]
    const staticContent = store.state[S.staticContent]
    const { content } = staticContent
    const { mainVideo } = content

    console.log(`Loaded: ${videoFormatNameToLoad}`)

    await axios.get(mainVideo[videoFormatNameToLoad], {
      onDownloadProgress: event => {
        store.commit(M.updateLoaderPercent, Math.round(event.loaded / event.total * 100) / 100)
      }
    })

    store.commit(M.updateMainVideoIsLoaded, true)

    next()
  } else {
    next()
  }
}
